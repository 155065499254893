@import "../App.scss";

.language_assistant {
  min-height: 100vh;
  min-width: 750px;
  width: calc(100vw - 250px);
  color: white;
  position: relative;
  left: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  header {
    display: flex;
    align-items: baseline;
    gap: 10px;

    h1 {
      color: $secondary;
    }
  }

  form {
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    gap: 20px;

    textarea {
      width: 100%;
      height: 200px;
      border-radius: 5px;
      border: 1px solid #ccc;
      padding: 10px;
      font-size: 16px;
      overflow: scroll;
      white-space: pre-line;
      resize: none;

      &:focus {
        border: 1px solid $secondary;
      }
    }

    button {
      width: 100%;
      height: 50px;
      border-radius: 5px;
      border: none;
      background-color: $secondary;
      color: $primaryDark;
      font-weight: bold;
      font-size: 20px;
      cursor: pointer;

      &:hover {
        background-color: $secondaryDark;
      }
    }

    section {
      width: 100%;
      background-color: $primary;
      min-height: 200px;
      border-radius: 5px;

      .result {
        width: 100%;
        padding: 10px;
        border-radius: 0 10px 10px 10px;
        text-align: justify;

        p {
          font-size: 1rem;
          color: whitesmoke;
          text-align: justify;
          line-height: 1.25rem;
          padding-bottom: 0.2rem;
        }
      }
    }
  }
}