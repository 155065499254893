.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.loader {
  position: relative;
  width: 104px;
  height: 1rem;
}

.loader span {
  position: absolute;
  top: calc(50%);
  left: calc(var(--i) * 20px);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #fff;
  animation: scaleAnimate 2s ease-in-out infinite;
  animation-delay: calc(var(--i) * 0.2s);
}

@keyframes scaleAnimate {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(2);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
